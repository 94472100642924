import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import styles from './Required.css';

class Required extends PureComponent {
  render() {
    return <span className={styles.required}>*</span>;
  }
}

export default Required;
