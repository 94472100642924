import React, { Component } from 'react';
import { Link as I18nLink, withTranslation } from '../../i18n';

class Link extends Component {
  render() {
    const { i18n, ...rest } = this.props;
    return <I18nLink lng={i18n.language} passHref {...rest} />;
  }
}

export default withTranslation('common')(Link);
