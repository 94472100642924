import React, { Component } from 'react';
import { withRouter } from 'next/router';
import cx from 'classnames';

import Link from './Link';
import styles from './styles.css';

class NavLink extends Component {
  render() {
    const { router, className, activeIfMatchesExactly, children, activeClassName, ...rest } = this.props;
    const { href } = this.props;
    const isActive = activeIfMatchesExactly ? router.pathname === href : router.pathname.indexOf(href) === 0;
    const classNames = cx(className, {
      [activeClassName]: isActive,
    });
    return <Link {...rest}>{React.cloneElement(children, { className: classNames })}</Link>;
  }
}

NavLink.defaultProps = {
  activeClassName: styles.isActive,
};

export default withRouter(NavLink);
