import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FaCircleNotch } from 'react-icons/fa';
import cx from 'classnames';

import styles from './styles.css';

class LoadingSpinner extends PureComponent {
  render() {
    const { className, absolute } = this.props;

    return (
      <span className={cx(styles.container, className, { [styles.absolute]: absolute })}>
        <FaCircleNotch className={styles.spinner} />
      </span>
    );
  }
}

LoadingSpinner.propTypes = {
  className: PropTypes.string,
  absolute: PropTypes.bool,
};

LoadingSpinner.defaultProps = {
  absolute: false,
};

export default LoadingSpinner;
