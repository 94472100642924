import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import Required from './Required';
import styles from './Input.css';

const errorsToIgnore = ['required'];

class Input extends PureComponent {
  isIgnoredError = error => {
    return errorsToIgnore.indexOf(error) > -1;
  };

  render() {
    const { label, helpText, required, input, meta, ...rest } = this.props;
    const Wrapper = label ? 'label' : React.Fragment;

    return (
      <Wrapper {...(label ? { className: styles.label } : {})}>
        {label && (
          <>
            {label}
            {helpText && <span className={styles.helpText}>{helpText}</span>}
            {required && <Required />}
          </>
        )}
        <Form.Control
          {...input}
          {...rest}
          required={required}
          isInvalid={meta.touched && (meta.error || meta.submitError)}
        />
        {meta.touched && (meta.error || meta.submitError) && !this.isIgnoredError(meta.error || meta.submitError) && (
          <Form.Control.Feedback type="invalid">{meta.error || meta.submitError}</Form.Control.Feedback>
        )}
      </Wrapper>
    );
  }
}

Input.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default Input;
