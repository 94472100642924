import Router from 'next/router';

import { i18n } from '../i18n';

const getRoute = url => {
  const language = i18n.language || i18n.options.defaultLanguage;
  return `/${language}${url}`;
};

export const routerPush = url => {
  Router.push(getRoute(url));
};

export const routerReplace = url => {
  Router.replace(getRoute(url));
};

export const routerSetWindowLocation = url => {
  window.location.href = getRoute(url);
};
