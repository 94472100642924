import fetch from 'isomorphic-unfetch';

import { routerReplace } from '../../utils/router';

import checkResponseStatus from './checkResponseStatus';
import AuthService from '../Auth';

export const API_URL = process.env.API_URL;

export default {
  fetch: async (ctx, url, data = {}) => {
    const token = AuthService.getTokenFromStorage(ctx);

    const response = await fetch(`${API_URL}/api${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...data,
        ...(token ? { __token: token } : {}),
      }),
    });

    return checkResponseStatus(response).catch(error => {
      if (error.status === 401) {
        if (ctx && ctx.req) {
          // ctx.res.writeHead(302, { Location: '/login?logout' });
          // ctx.res.end();
          // return;
        }
        // routerReplace('/login');
      } else {
        throw error;
      }
    });
  },
};
