import { camelizeKeys } from 'humps';

const getResponseData = async response => {
  const contentType = response.headers.get('content-type');

  if (contentType && contentType.indexOf('application/json') !== -1) {
    const json = await response.json();
    return camelizeKeys(json);
  }

  return response.text();
};

export default getResponseData;
