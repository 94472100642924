import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FaCircleNotch } from 'react-icons/fa';

import LoadingSpinner from '../LoadingSpinner';
import styles from './styles.css';

class Button extends PureComponent {
  ref = createRef();

  handleMouseUp = event => {
    this.blur();
    if (this.props.onMouseUp) {
      this.props.onMouseUp(event);
    }
  };

  handleMouseLeave = event => {
    this.blur();
    if (this.props.onMouseLeave) {
      this.props.onMouseLeave(event);
    }
  };

  blur() {
    if (this.ref.current && this.ref.current.blur) {
      this.ref.current.blur();
    }
  }

  render() {
    const {
      children,
      className,
      level,
      disabled,
      element,
      size,
      type,
      processing,
      fullWidth,
      icon,
      ...others
    } = this.props;

    const enhancedChildren = (
      <React.Fragment>
        {icon && <span className={styles.icon}>{icon}</span>}
        {children}
        {processing && <LoadingSpinner absolute />}
      </React.Fragment>
    );

    const classNames = cx(
      styles.button,
      styles[level],
      styles[size],
      {
        [styles.isDisabled]: disabled,
        [styles.isProcessing]: processing,
        [styles.isFullWidth]: fullWidth,
      },
      className,
    );

    const props = {
      ...others,
      ref: this.ref,
      className: classNames,
      disabled: element === 'button' ? disabled : null,
      onMouseUp: this.handleMouseUp,
      onMouseLeave: this.handleMouseLeave,
      type: element === 'button' ? type : null,
    };

    return React.createElement(element, props, enhancedChildren);
  }
}

Button.propTypes = {
  level: PropTypes.oneOf(['primary', 'secondary', 'destructive', 'white', 'facebook', 'outline']),
  size: PropTypes.oneOf(['small', 'medium']),
  element: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  processing: PropTypes.bool,
  fullWidth: PropTypes.bool,
  icon: PropTypes.any,
};

Button.defaultProps = {
  element: 'button',
  level: 'primary',
  size: 'medium',
  type: 'button',
  fullWidth: false,
};

export default Button;
