const NextI18Next = require('next-i18next').default;

module.exports = new NextI18Next({
  defaultLanguage: 'nl',
  otherLanguages: ['fr'],
  localeSubpaths: {
    nl: 'nl',
    fr: 'fr',
  },
});
