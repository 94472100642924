import nextCookie from 'next-cookies';
import API from '../API';

export default {
  login: (ctx, email, password) => {
    return API.fetch(ctx, '/auth/login', { email, password });
  },
  forgotPassword: (ctx, email, language) => {
    return API.fetch(ctx, '/auth/forgot-password', { email, language });
  },
  resetPassword: (ctx, password, token) => {
    return API.fetch(ctx, '/auth/reset-password', { password, token });
  },
  changePassword: (ctx, password, newPassword) => {
    return API.fetch(ctx, '/auth/change-password', { password, new_password: newPassword });
  },
  facebookLogin: (ctx, accessToken) => {
    return API.fetch(ctx, '/auth/facebook-login', { access_token: accessToken });
  },
  facebookRegister: (ctx, accessToken) => {
    return API.fetch(ctx, '/auth/facebook-register', { access_token: accessToken });
  },
  getTokenFromStorage: ctx => {
    return nextCookie(ctx).token;
  },
  getToken: ctx => {
    return nextCookie(ctx).token;
  },
  storeToken: token => {
    document.cookie = `token=${token}; path=/;`;
  },
  removeTokenFromStorage: () => {
    document.cookie = `token=; path=/;`;
  },
  fetchUserData: ctx => {
    return API.fetch(ctx, '/auth/me');
  },
};
